import axios  from "axios";

const baseUrl = process.env.VUE_APP_API_URL

export const ger3ahApi = {
    //homePage
    getNumberOfNameLeft: () => {
       return axios.get(`${baseUrl}/api/Ger3ah/GetAllGer3ahNames`);
    },
    pickAName: (name, email) => {
        return axios.get(`${baseUrl}/api/Ger3ah/NamePicker?name=${name}&email=${email}`)
    },
    CheckIfTheGer3ahIsOn: () => {
        return axios.get(`${baseUrl}/api/Ger3ah/CheckIfTheGer3ahIsOn`);
    },

    //HestoryPage
    getGer3ahHestory: (name) => {
        return axios.get(`${baseUrl}/api/Ger3ah/GetGer3ahHestory?name=${name}`)
    },

    //admin
    reBuildTheGer3ah:() => {
        return axios.get(`${baseUrl}/api/Ger3ah/ReBuildTheGer3ah`)
    },
    GetGer3ahNameForAdmin: () => {
        return axios.get(`${baseUrl}/api/Ger3ah/GetAllGer3ahNamesForAdmin`)
    },
    UpdateIsInTheGer3ah: (ids) => {
        return axios.post(`${baseUrl}/api/Ger3ah/UpdateIsInTheGer3ah`, ids)
    },
    UpdateGer3ahStatus: (bool) => {
        return axios.get(`${baseUrl}/api/Ger3ah/UpdateGer3ahStatus?status=${bool}`,)
    },
    AddingNewUser: (name, nameAR) => {
        return axios.get(`${baseUrl}/api/Ger3ah/AddNewUser?name=${name}&nameAR=${nameAR}`,)
    },

    removeNameFromGer3ah:(nameThatWillRemoved) => {
        return axios.get(`${baseUrl}/api/Ger3ah/RemoveNameFromGer3ah?name=${nameThatWillRemoved}`)
    },
    insertTheTabels:()=>{
        return axios.get(`${baseUrl}/api/Ger3ah/GenrateDataForDB`)
    }
};